@if (current.mode && current.step) {
<div class="breadcrumb">
  <button type="button" (click)="goBackUrl()" class="back-button">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12ZM24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM17.25 11.25C17.6642 11.25 18 11.5858 18 12C18 12.4142 17.6642 12.75 17.25 12.75H8.56066L11.7803 15.9697C12.0732 16.2626 12.0732 16.7374 11.7803 17.0303C11.4874 17.3232 11.0126 17.3232 10.7197 17.0303L6.21967 12.5303C5.92678 12.2374 5.92678 11.7626 6.21967 11.4697L10.7197 6.96967C11.0126 6.67678 11.4874 6.67678 11.7803 6.96967C12.0732 7.26256 12.0732 7.73744 11.7803 8.03033L8.56066 11.25H17.25Z"
        fill="#83807C"
      />
    </svg>
  </button>

  @for (breadcrumb of breadcrumbs; track breadcrumb; let i = $index;) {
  <a
    href="{{ breadcrumb.url }}"
    class="breadcrumb-step {{ current.step > i + 1 ? 'after' : '' }} {{
      current.step === i + 1 ? 'active' : ''
    }}"
  >
    {{ breadcrumb.label }}
  </a>

  @if (i < breadcrumbs.length - 1) {
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M8.69732 6.52247L3.92459 9.29137C3.51938 9.52646 3 9.24212 3 8.76915V3.23134C3 2.75837 3.51938 2.47403 3.92459 2.70912L8.69732 5.47802C9.10089 5.71215 9.10089 6.28834 8.69732 6.52247Z"
      fill="#83807C"
    />
  </svg>
  } }
</div>

}
