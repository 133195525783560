import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  isTrue: boolean = false;
  isRoot: boolean = false;

  constructor(private loaderService: LoaderService, private router: Router) {}

  ngOnInit(): void {
    this.loaderService.getLoader().subscribe((state) => {
      this.isRoot = this.router.url === '/' ? true : false;
      this.isTrue = state;
    });
  }
}
