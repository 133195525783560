<mat-toolbar class="bg-white header">
  <!-- <app-breadcrumb></app-breadcrumb> -->
  <div class="title-container" (click)="goTo(title.url)">
    <mat-icon svgIcon="{{ title.icon }}"> </mat-icon>
    <span class="title"> {{ title.label }} </span>
  </div>
  <button
    matTooltip="Logout"
    mat-icon-button
    class="icon"
    aria-label="icon-button with share icon"
    (click)="goTo('/auth/logout')"
  >
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>
