import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  active: string = 'dashboard';

  constructor(private router: Router) {}

  ngOnInit() {
    let urlParts = this.router.url.split('/');
    let contains = urlParts[1];
    switch (contains) {
      case 'dashboard':
        this.active = 'dashboard';
        break;
      case 'lesson-planner':
        this.active = 'lesson-planner';
        break;
      case 'profile':
        this.active = 'profile';
        break;
      default:
        this.active = 'dashboard';
        break;
    }
  }

  sidemenuActive(menu: string) {
    this.active = menu;
  }
}
