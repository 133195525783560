import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Toast } from '../../models/toast.interface';
import { ToasterService } from '../../services/toaster.services';

@Component({
  selector: 'app-toaster-container',
  templateUrl: './toaster-container.component.html',
  styleUrls: ['./toaster-container.component.scss']
})
export class ToasterContainerComponent implements OnInit {

  toasts: Toast[] = [];
  subscriptions: Subscription = new Subscription();
  constructor(private toaster: ToasterService) {}

  ngOnInit() {
    this.toaster.toast$.subscribe((toast) => {
      this.toasts = [toast!, ...this.toasts];
      this.remove(1);
      setTimeout(() => this.toasts.pop(), toast!.delay || 6000);
    });
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
