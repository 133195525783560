<div
  class="row toast toast-{{ toast!.type }} show line"
  [style.top.px]="i ? i * 120 : i * 120 + 20"
>
  <div class="col-12">
    <a class="close" (click)="remove.emit(i)">&times;</a>
  </div>

  <div class="col-12 p-0">
    <h4 class="toast-heading m-0">{{ toast!.title }}</h4>
  </div>
  <div class="toast-body col-12 p-0">
    <p [innerHTML]="toast!.body"></p>
  </div>
</div>
