<a href="/">
  <img
    class="logo d-block mx-auto mt-3 mb-5"
    src="assets/images/logo-classroom-studio.png"
  />
</a>
<div class="button-row">
  <a routerLink="/">
    <button
      type="button"
      mat-flat-button
      matTooltip="Dashboard"
      matTooltipPosition="right"
      class="{{ active === 'dashboard' ? 'active' : '' }}"
      (click)="sidemenuActive('dashboard')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.2386 2.78245C12.9876 1.68942 11.0124 1.68942 9.76139 2.78245L3.11313 8.59111C2.95024 8.73343 2.84067 8.91882 2.79995 9.121C2.00366 13.075 1.94488 17.1228 2.62609 21.0946L2.78778 22.0373H7.06499V13.9101C7.06499 13.3681 7.54752 12.9287 8.14275 12.9287H15.8572C16.4525 12.9287 16.935 13.3681 16.935 13.9101V22.0373H21.2122L21.3739 21.0946C22.0551 17.1228 21.9963 13.075 21.2 9.121C21.1593 8.91882 21.0498 8.73343 20.8869 8.59111L14.2386 2.78245ZM8.26898 1.36629C10.354 -0.455429 13.646 -0.455429 15.731 1.36629L22.3793 7.17495C22.8687 7.60254 23.1979 8.15954 23.3202 8.767C24.1602 12.9379 24.2222 17.2078 23.5036 21.3974L23.2439 22.9119C23.1364 23.5382 22.544 24 21.8478 24H15.8572C15.262 24 14.7795 23.5606 14.7795 23.0187V14.8914H9.22051V23.0187C9.22051 23.5606 8.73798 24 8.14275 24H2.15223C1.45602 24 0.86356 23.5382 0.756136 22.9119L0.496378 21.3974C-0.222198 17.2078 -0.160195 12.9379 0.679783 8.767C0.80212 8.15954 1.13132 7.60254 1.62072 7.17494L8.26898 1.36629Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </a>
  <a routerLink="/lesson-planner">
    <button
      type="button"
      mat-flat-button
      matTooltip="Lesson Planner"
      matTooltipPosition="right"
      class="{{ active === 'lesson-planner' ? 'active' : '' }}"
      (click)="sidemenuActive('lesson-planner')"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.556 18.2803C11.6989 18.376 11.8516 18.4184 12 18.4163C12.1484 18.4184 12.3011 18.376 12.444 18.2803L13.7112 17.4309C16.1966 15.7652 19.2363 15.4932 21.9274 16.6956C22.9201 17.1391 24 16.3225 24 15.1281V2.59071C24 1.87006 23.6072 1.22202 23.0082 0.95438C19.5421 -0.594311 15.627 -0.243905 12.4259 1.90153L12 2.18697L11.5741 1.90153C8.37295 -0.243905 4.45794 -0.594311 0.991794 0.95438C0.392785 1.22202 0 1.87006 0 2.59071V15.1281C0 16.3225 1.07988 17.1391 2.07261 16.6956C4.76374 15.4932 7.80337 15.7652 10.2888 17.4309L11.556 18.2803ZM1.67442 2.66406V14.8622C4.7953 13.5554 8.28479 13.9067 11.1502 15.8271L11.1628 15.8355V3.80705L10.7126 3.50534C7.97887 1.67316 4.63963 1.36351 1.67442 2.66406ZM12.8498 15.8271L12.8372 15.8355V3.80705L13.2874 3.50534C16.0211 1.67316 19.3604 1.36351 22.3256 2.66406V14.8622C19.2047 13.5554 15.7152 13.9067 12.8498 15.8271Z"
          fill="currentColor"
        />
        <path
          d="M8.95832 20.7868C6.69913 19.3147 3.90552 19.3147 1.64633 20.7868L1.53164 20.8615C1.13225 21.1218 0.997346 21.6944 1.23032 22.1405C1.4633 22.5866 1.97594 22.7373 2.37533 22.4771L2.49002 22.4024C4.22786 21.27 6.37679 21.27 8.11463 22.4024L9.32831 23.1932C10.9793 24.2689 13.0207 24.2689 14.6717 23.1932L15.8854 22.4024C17.6232 21.27 19.7721 21.27 21.51 22.4024L21.6247 22.4771C22.0241 22.7373 22.5367 22.5866 22.7697 22.1405C23.0027 21.6944 22.8678 21.1218 22.4684 20.8615L22.3537 20.7868C20.0945 19.3147 17.3009 19.3147 15.0417 20.7868L13.828 21.5776C12.6984 22.3137 11.3016 22.3137 10.172 21.5776L8.95832 20.7868Z"
          fill="currentColor"
        />
      </svg>
    </button>
  </a>
  <!--<button mat-flat-button matTooltip="Library" matTooltipPosition="right" class="{{ active === 'library' ? 'active' : '' }}" (click)="sidemenuActive('library')">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0001 0L21.9148 4.5H23.2501C23.6643 4.5 24.0001 4.83579 24.0001 5.25L24 8.25C24 8.66421 23.6642 9 23.25 9H22.5L22.5001 19.5C22.8442 19.5 23.1442 19.7361 23.2277 20.07L23.9777 23.07C24.0337 23.2941 23.9833 23.533 23.8412 23.715C23.6991 23.897 23.481 24 23.2501 24H0.750053C0.519102 24 0.301029 23.897 0.158899 23.715C0.0167685 23.533 -0.0335679 23.2941 0.0224461 23.07L0.772446 20.07C0.855915 19.7361 1.1559 19.5 1.50005 19.5L1.5 9H0.75C0.335786 9 0 8.66421 0 8.25L5.29289e-05 5.25C5.29289e-05 4.83579 0.335839 4.5 0.750053 4.5H2.08533L12.0001 0ZM5.66478 4.5H18.3353L12.0001 1.5L5.66478 4.5ZM3 9L3.00005 19.5H4.50005L4.5 9H3ZM6 9L6.00005 19.5H9.75005L9.75 9H6ZM11.25 9L11.2501 19.5H12.7501L12.75 9H11.25ZM14.25 9L14.2501 19.5H18.0001L18 9H14.25ZM19.5 9L19.5001 19.5H21V9H19.5ZM22.5001 7.5V6H1.50005V7.5H22.5001ZM21.9145 21H2.08564L1.71064 22.5H22.2895L21.9145 21Z" fill="currentColor"/>
    </svg>
  </button>-->
  <a routerLink="/profile">
    <button
      type="button"
      mat-flat-button
      matTooltip="Profile"
      matTooltipPosition="right"
      class="profile {{ active === 'profile' ? 'active' : '' }}"
      (click)="sidemenuActive('profile')"
    >
      <mat-icon>account_circle</mat-icon>
    </button></a
  >
</div>
