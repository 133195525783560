import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ToasterComponent } from './toaster/toaster.component';
import { ToasterContainerComponent } from './toaster-container/toaster-container.component';
import { LoaderComponent } from './loader/loader.component';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box.component';
import { UpdateConfirmationBoxComponent } from './update-confirmation-box/update-confirmation-box.component';
import { CloseConfirmationBoxComponent } from './close-confirmation-box/close-confirmation-box.component';

const routes: Routes = [
  {
    path: '',
    component: SidebarComponent,
  },
];

@NgModule({
  declarations: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ToasterComponent,
    ToasterContainerComponent,
    LoaderComponent,
    ConfirmationBoxComponent,
    UpdateConfirmationBoxComponent,
    CloseConfirmationBoxComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatChipsModule,
    MatTooltipModule,
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    BreadcrumbComponent,
    ToasterComponent,
    ToasterContainerComponent,
    LoaderComponent,
    ConfirmationBoxComponent,
    RouterModule,
  ],
})
export class ComponentsModule {}
